<template><div><h1 id="chromeos-boot-modes" tabindex="-1"><a class="header-anchor" href="#chromeos-boot-modes"><span>ChromeOS Boot Modes</span></a></h1>
<p>ChromeOS has several different boot modes, which are important to understand in the context of modifying your device to run an alternate OS:</p>
<ul>
<li>
<p><RouteLink to="/docs/boot-modes/normal.html">Normal Mode</RouteLink></p>
</li>
<li>
<p><RouteLink to="/docs/boot-modes/recovery.html">Recovery Mode</RouteLink></p>
</li>
<li>
<p><RouteLink to="/docs/boot-modes/developer.html">Developer Mode</RouteLink></p>
</li>
<li>
<p><RouteLink to="/docs/boot-modes/legacy.html">Legacy Boot Mode</RouteLink></p>
</li>
</ul>
</div></template>


